var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_print_page" },
    [
      _vm.cantPrint
        ? [
            _c(
              "div",
              { staticClass: "cant_print d-flex align-center justify-center" },
              [
                _vm._v(
                  "\n      Sorry，打印数据已丢失，不支持同时打印多项任务，请进入妙行重新打印\n    "
                ),
              ]
            ),
          ]
        : _vm._e(),
      !_vm.cantPrint
        ? [
            _c(
              "div",
              { staticClass: "print_wrapper" },
              [
                _vm.printHint
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "print_bar d-flex align-center justify-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "print_button cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.doPrint.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("\n            点此进行打印\n          ")]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                [
                  _vm.largeTask
                    ? _c("task-view", {
                        staticClass: "task_view",
                        attrs: {
                          task: _vm.largeTask.otask,
                          "large-task": _vm.largeTask,
                          "switch-teaching": _vm.switchTeaching,
                          "print-hint": _vm.printHint,
                        },
                        on: {
                          "update:switchTeaching": function ($event) {
                            _vm.switchTeaching = $event
                          },
                          "update:switch-teaching": function ($event) {
                            _vm.switchTeaching = $event
                          },
                          handleSwitchTeaching: _vm.handleSwitchTeaching,
                        },
                      })
                    : _vm._e(),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }