var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_print_page_task_view_main_task_title bbox d-flex align-center",
    },
    [
      _c("div", { staticClass: "icon flex-shrink-0" }),
      _c("title-status-tag", {
        staticClass: "mr-5",
        attrs: {
          levelStatus: _vm.task.task.levelStatus,
          processStatus: String(_vm.task.task.processStatus),
        },
      }),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.task.task.name) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }