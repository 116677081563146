var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_print_page_task_view_side_bar_teaching" },
    [
      _c("div", { staticClass: "title_group" }, [_vm._v("\n    教学内容\n  ")]),
      _c("fun-component", {
        staticClass: "make_fun mt-20",
        attrs: {
          title: "简介",
          type: "intro",
          edit: false,
          use: false,
          preview: false,
          open_add: false,
        },
      }),
      _vm._m(0),
      _c("fun-component", {
        staticClass: "make_fun mt-20",
        attrs: {
          title: "视频",
          type: "video",
          edit: false,
          use: false,
          preview: false,
        },
      }),
      _c("video-part", {
        ref: "video",
        staticClass: "part_group",
        attrs: {
          list: _vm.videoList,
          uploading: false,
          edit: false,
          use: false,
          preview: false,
        },
      }),
      _c("fun-component", {
        staticClass: "make_fun mt-20",
        attrs: {
          title: "文件",
          type: "file",
          edit: false,
          use: false,
          preview: false,
        },
      }),
      _c("file-part", {
        ref: "file",
        staticClass: "part_group",
        attrs: {
          list: _vm.fileList,
          uploading: false,
          edit: false,
          use: false,
          preview: false,
        },
      }),
      _c("fun-component", {
        staticClass: "make_fun mt-20",
        attrs: {
          title: "链接",
          type: "hyperlink",
          edit: false,
          use: false,
          preview: false,
        },
      }),
      _c("hyperlink-part", {
        ref: "hyperlink",
        staticClass: "part_group",
        attrs: {
          list: _vm.linkList,
          edit: false,
          use: true,
          sell: false,
          preview: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "intro_group pa-10" }, [
      _c("div", { staticClass: "intro" }, [_vm._v("\n      暂无简介\n    ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }