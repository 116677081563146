<template>
  <div
    class="v_print_page_task_view_main_task_title bbox d-flex align-center"
  >
    <div
      class="icon flex-shrink-0"
    ></div>
    <title-status-tag
      class="mr-5"
      :levelStatus="task.task.levelStatus"
      :processStatus="String(task.task.processStatus)"
    ></title-status-tag>
    <div
      class="title"
    >
      {{ task.task.name }}
    </div>
  </div>
</template>
<script>
import TitleStatusTag from '@/components/Task/Main/LineBar/TaskTitle/TitleStatusTag'
export default {
  components: {
    TitleStatusTag
  },
  props:{
    task: {
      type: Object,
      default: undefined
    }
  }
}
</script>
<style scoped lang="scss">
.v_print_page_task_view_main_task_title {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f4f4f4;
  min-height: 50px;
  .icon {
    @include background;
    width:24px;
    height: 24px;
    background-image: url(~@/assets/images/task/task_title_icon.png);
    margin-right: 16px;
  }
  .title {
    @include placeholder(#acacac, bold);
    width: 100%;
    flex: 1;
    border: none;
    outline: none;
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }
}
</style>