<template>
  <div
    class="print_page_task_view"
  >
    <fun-bar
      :task="task"
      :switch-teaching.sync="switchTeaching"
      :print-hint="printHint"
      @handleSwitchTeaching="handleSwitchTeaching"
    ></fun-bar>
    <div
      class="d-flex main_group align-stretch"
    >
      <main-component
        :task="task"
      ></main-component>
      <side-bar-component
        v-if="showTeach && switchTeaching"
        class="flex-shrink-0"
        :large-task="largeTask"
      ></side-bar-component>
    </div>
  </div>
</template>

<script>
import FunBar from './TaskView/FunBar'
import MainComponent from './TaskView/Main'
import SideBarComponent from './TaskView/SideBar'
export default {
  name: 'TaskView',
  props: {
    printHint: {
      type: Boolean,
      defualt: true,
    },
    largeTask: {
      type: Object,
      default: undefined
    },
    switchTeaching: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    task() {
      const { largeTask } = this
      if (largeTask) {
        return largeTask.otask
      }
      return undefined
    },
    teachData() {
      const { largeTask } = this
      if (largeTask) {
        return largeTask.teachData
      }
      return undefined
    },
    fileList() {
      const { teachData } = this
      if (teachData) {
        return teachData.fileList
      }
      return undefined
    },
    videoList() {
      const { teachData } = this
      if (teachData) {
        return teachData.videoList
      }
      return undefined
    },
    linkList() {
      const { teachData } = this
      if (teachData) {
        return teachData.linkList
      }
      return undefined
    },
    showTeach() {
      const { fileList, videoList, linkList } = this
      if (fileList && fileList.length) {
        return true
      }
      if (videoList && videoList.length) {
        return true
      }
      if (linkList && linkList.length) {
        return true
      }
      return false
    }
  },
  components: {
    MainComponent,
    FunBar,
    SideBarComponent
  },
  methods: {
    handleSwitchTeaching(props) {
      console.log(props)
      // this.$emit('update:switchTeaching', !this.switchTeaching)
      this.$emit('handleSwitchTeaching', props)
    }
  }
}
</script>

<style scoped lang="scss">
.print_page_task_view{
  width: 100%;
  background-color:#fff;
  .main_group{
    width: 100%;
    height: 100%;
    flex: 1;
    //background-color: #f8f8f8;
    //overflow: hidden;
  }
  .main{
    flex:1;
  }
}
</style>
