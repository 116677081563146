var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_print_page_task_view_main_task_tag bbox d-flex align-center",
    },
    [
      _c(
        "overlay-scrollbars",
        {
          ref: "overlay",
          staticClass: "overlay_container bbox d-flex align-center",
        },
        [
          _c(
            "div",
            { staticClass: "list d-flex align-center" },
            [
              _vm._l(_vm.list, function (item, index) {
                return [
                  _c("div", { key: index, staticClass: "tag flex-shrink-0" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n            " + _vm._s(item.tagName) + "\n          "
                      ),
                    ]),
                  ]),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "tag_list_group bbox",
          class: { active: _vm.switch_tag_list },
        },
        [
          _c(
            "div",
            {
              staticClass: "tag_list bbox",
              on: {
                click: function ($event) {
                  return _vm.$refs.input.focus()
                },
              },
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v("\n        选择已有标签\n      "),
              ]),
              _c(
                "div",
                { staticClass: "tag_group bbox d-flex align-center flex-wrap" },
                [
                  _vm._l(_vm.tag_list, function (val, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: index,
                          staticClass: "tag cp",
                          class: { on: val.selected },
                          on: {
                            click: function ($event) {
                              return _vm.selectTagList(val)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(val.tagName) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }