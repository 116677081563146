var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_print_page_task_view_main_widget_wrapper bbox d-flex flex-column bbox",
    },
    [
      !_vm.list || (_vm.list && _vm.list.length == 0)
        ? [_vm._t("default")]
        : _vm._e(),
      _c(
        "draggable",
        {
          staticClass: "draggable_wrapper",
          class: { position: !_vm.relevancy },
          style: { minHeight: `${_vm.minHeight}px` },
          attrs: {
            list: _vm.list,
            group: { name: `widget`, pull: false },
            handle: ".components_task_main_widget_title_page .icon",
            animation: "300",
            id: `control_section${_vm.relevancy ? "relevancy" : ""}`,
          },
          on: { change: _vm.changeLog },
        },
        [
          _vm._l(_vm.list, function (item) {
            return [
              _c("widget-wrapper", {
                key: item.id
                  ? `widget_id_${item.id}`
                  : `widget_sort_${item.widget_sort}`,
                ref: "widget",
                refInFor: true,
                staticClass: "widget_wrapper",
                attrs: {
                  item: item,
                  relevancy: _vm.relevancy,
                  editable: _vm.editable,
                  parent: _vm.parent,
                  public_notice: _vm.public_notice,
                  recycle: _vm.recycle,
                  sell: _vm.sell,
                  system: _vm.system,
                  shopping_mail: _vm.shopping_mail,
                  heightlight: _vm.$api.moduleTask.widgetHeightlight(item),
                },
                on: {
                  handleRepeal: _vm.repealWidget,
                  handleDelete: _vm.deleteWidget,
                  handleDeleteRelevancy: _vm.deleteRelevancy,
                  handleMofifiedSign: _vm.modifiedSign,
                  handleSortFile: _vm.handleSortFile,
                  setZIndex: _vm.setZIndex,
                },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }