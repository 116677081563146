var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "print_page_task_view" },
    [
      _c("fun-bar", {
        attrs: {
          task: _vm.task,
          "switch-teaching": _vm.switchTeaching,
          "print-hint": _vm.printHint,
        },
        on: {
          "update:switchTeaching": function ($event) {
            _vm.switchTeaching = $event
          },
          "update:switch-teaching": function ($event) {
            _vm.switchTeaching = $event
          },
          handleSwitchTeaching: _vm.handleSwitchTeaching,
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex main_group align-stretch" },
        [
          _c("main-component", { attrs: { task: _vm.task } }),
          _vm.showTeach && _vm.switchTeaching
            ? _c("side-bar-component", {
                staticClass: "flex-shrink-0",
                attrs: { "large-task": _vm.largeTask },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }