var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_print_page_task_view_main_task_users bbox d-flex align-center flex-wrap align-content-center",
    },
    [
      _c("div", { staticClass: "icon flex-shrink-0" }),
      _c(
        "div",
        { staticClass: "list flex-shrink-0 d-flex align-center flex-wrap" },
        [
          _vm._l(_vm.taskUsers, function (item, index) {
            return [
              _c("task-users-name", {
                key: index,
                staticClass: "mr-5 flex-shrink-0",
                attrs: { "user-info": item, "friends-list": _vm.friendsList },
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }