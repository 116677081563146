var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_print_page_task_view_side_bar flex-shrink-0 bbox" },
    [_c("teaching-component", { attrs: { "large-task": _vm.largeTask } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }