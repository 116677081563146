var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_print_page_task_view_bar_page d-flex justify-space-between bbox align-center",
    },
    [
      _c("div", { staticClass: "origin_group" }, [
        _vm._v("\n    来源: " + _vm._s(_vm.origin) + "\n  "),
      ]),
      _c("div", { staticClass: "fun_group" }, [
        _vm.printHint
          ? _c(
              "div",
              {
                staticClass: "switch cp un_sel",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSwitchTeaching.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n      收起教学\n    ")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }