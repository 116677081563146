<template>
<div
  class="v_print_page_task_view_side_bar flex-shrink-0 bbox"
>
  <teaching-component
    :large-task="largeTask"
  ></teaching-component>
</div>
</template>

<script>
import TeachingComponent from './Teaching'
export default {
  components: {
    TeachingComponent
  },
  props: {
    largeTask: {
      type: Object,
      default: undefined
    }
  },
}
</script>

<style scoped lang="scss">
.v_print_page_task_view_side_bar {
  border-left: 2px solid #f4f4f4;
  padding: 10px;
  width: 400px;
}
</style>
