<template>
  <div
    class="v_print_page_task_view_bar_page d-flex justify-space-between bbox align-center"
  >
    <div
      class="origin_group"
    >
      来源: {{origin}}
    </div>
    <div
      class="fun_group"
    >
      <div
        v-if="printHint"
        class="switch cp un_sel"
        @click.stop="handleSwitchTeaching"
      >
        收起教学
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FunBar',
  props: {
    task: {
      type: Object,
      default: function () {
        return {}
      },
    },
    switchTeaching: {
      type: Boolean,
      default: true
    },
    printHint: {
      type: Boolean,
      defualt: true,
    },
  },
  computed: {
    origin() {
      const { task } = this
      if (task.task) {
        const { from } = task.task
        return from
      }
      return ''
    },
  },
  methods: {
    handleSwitchTeaching() {
      console.log(this.switchTeaching)
      // this.$emit('update:switchTeaching', !this.switchTeaching)
      this.$emit('handleSwitchTeaching', !this.switchTeaching)
    }
  }
}
</script>

<style scoped lang="scss">
.v_print_page_task_view_bar_page {
  border-bottom: 1px solid #F4F4F4;
  height: 50px;
  padding: 0 20px;
  padding-right: 10px;
  width: 100%;
  .origin_group {
    font-size: 16px;
    color: #999999;
  }
  .fun_group {
    .switch {
      font-size: 14px;
      color: $main_blue;
      margin-right: 10px;
    }
  }
}
</style>