<template>
<div
  class="v_print_page_task_view_side_bar_teaching"
>
  <div
    class="title_group"
  >
    教学内容
  </div>
  <fun-component
    class="make_fun mt-20"
    title="简介"
    type="intro"
    :edit="false"
    :use="false"
    :preview="false"
    :open_add="false"
  ></fun-component>
  <div
    class="intro_group pa-10"
  >
    <div
      class="intro"
    >
      暂无简介
    </div>
  </div>
  <fun-component
    class="make_fun mt-20"
    title="视频"
    type="video"
    :edit="false"
    :use="false"
    :preview="false"
  ></fun-component>
  <video-part
    class="part_group"
    ref="video"
    :list="videoList"
    :uploading="false"
    :edit="false"
    :use="false"
    :preview="false"
  ></video-part>
  <fun-component
    class="make_fun mt-20"
    title="文件"
    type="file"
    :edit="false"
    :use="false"
    :preview="false"
  ></fun-component>
  <file-part
    class="part_group"
    ref="file"
    :list="fileList"
    :uploading="false"
    :edit="false"
    :use="false"
    :preview="false"
  ></file-part>
  <fun-component
    class="make_fun mt-20"
    title="链接"
    type="hyperlink"
    :edit="false"
    :use="false"
    :preview="false"
  ></fun-component>
  <hyperlink-part
    class="part_group"
    ref="hyperlink"
    :list="linkList"
    :edit="false"
    :use="true"
    :sell="false"
    :preview="false"
  ></hyperlink-part>
</div>
</template>

<script>
import FunComponent from '@/components/Task/SideBar/Teach/Pages/Make/Fun'
import VideoPart from '@/components/Task/SideBar/Teach/Pages/Make/VideoPart.vue'
import FilePart from '@/components/Task/SideBar/Teach/Pages/Make/FilePart.vue'
import HyperlinkPart from '@/components/Task/SideBar/Teach/Pages/Make/HyperlinkPart.vue'
export default {
  components: {
    FunComponent,
    VideoPart,
    FilePart,
    HyperlinkPart
  },
  props: {
    largeTask: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    task() {
      const { largeTask } = this
      if (largeTask) {
        return largeTask.otask
      }
      return undefined
    },
    teachData() {
      const { largeTask } = this
      if (largeTask) {
        return largeTask.teachData
      }
      return undefined
    },
    fileList() {
      const { teachData } = this
      if (teachData) {
        return teachData.fileList
      }
      return undefined
    },
    videoList() {
      const { teachData } = this
      if (teachData) {
        return teachData.videoList
      }
      return undefined
    },
    linkList() {
      const { teachData } = this
      if (teachData) {
        return teachData.linkList
      }
      return undefined
    }
  },
}
</script>

<style scoped lang="scss">
.v_print_page_task_view_side_bar_teaching {
  .title_group {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
}
</style>
