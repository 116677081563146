<template
  comments
>
  <!-- comments 标记保留注释 -->
  <div
    class="v_print_page"
  >
    <template
      v-if="cantPrint"
    >
      <div
        class="cant_print d-flex align-center justify-center"
      >
        Sorry，打印数据已丢失，不支持同时打印多项任务，请进入妙行重新打印
      </div>

    </template>
    <template
      v-if="!cantPrint"
    >
      <div
        class="print_wrapper"
      >
        <template
          v-if="printHint"
        >
          <div
            class="print_bar d-flex align-center justify-center"
          >
            <div
              class="print_button cp"
              @click.stop="doPrint"
            >
              点此进行打印
            </div>
          </div>
        </template>
        <template>
          <task-view
            v-if="largeTask"
            class="task_view"
            :task="largeTask.otask"
            :large-task="largeTask"
            :switch-teaching.sync="switchTeaching"
            :print-hint="printHint"
            @handleSwitchTeaching="handleSwitchTeaching"
          ></task-view>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import tools from '@/api/tools'
import { LargeTask } from '@/classes'
import router from '@/router'
import TaskView from '@/views/PrintPage/TaskView'
export default {
  name: 'index',
  components: {
    TaskView
  },
  data() {
    return {
      cantPrint: false,
      printHint: true,
      largeTask: undefined,
      switchTeaching: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    handleSwitchTeaching(props) {
      this.switchTeaching = props
    },
    init() {
      console.log(window, document)
      document.title = '任务打印'
      const printData = tools.getCookie('printData')
      const largeTask = new LargeTask(printData.largeTask)
      this.$set(this, 'largeTask', largeTask)
      if (Number(largeTask.otask.task.id !== Number(router.history.current.query.taskId))) {
        this.cantPrint = true
        document.title = '打印数据丢失'
      } else {
        document.title = `${largeTask.otask.task.name} - 任务打印`
      }
    },
    doPrint() {
      this.printHint = false
      this.$nextTick(function() {
        window.print()
        this.printHint = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.v_print_page {
  .cant_print {
    width: 100vw;
    height: 100vh;
    text-align: center;
    font-size: 18px;
    color: #999;
    font-weight: bold;
    line-height: 100%;
  }
  .print_wrapper {
    width: 100%;
    -webkit-print-color-adjust: exact;
    page-break-inside:avoid;
    position: relative;
    .print_bar {
      width: 100%;
      background-color: #f2f2f2;
      position: sticky;
      top: 0;
      left: 0;
      padding: 10px 0;
      z-index: 3;
      .print_button{
        color: $main_blue;
        font-weight: bold;
        font-size: 20px;
      }
    }
    .task_view {
      position: relative;
      z-index: 2;
    }
  }
  @page {
    size: A4 portrait;
    margin: 0 auto;
  }
}
@media print {
  .v_print_page {
    width: 210mm; // A4纸的宽度
  }
}

</style>
