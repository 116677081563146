var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_print_page_task_view_main_task_users_name" },
    [_vm._v("\n  " + _vm._s(_vm.userName) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }