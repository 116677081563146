<template>
  <div
    class="v_print_page_task_view_main_task_users bbox d-flex align-center flex-wrap align-content-center"
  >
    <div
      class="icon flex-shrink-0"
    ></div>
    <div
      class="list flex-shrink-0 d-flex align-center flex-wrap"
    >
      <template
        v-for="(item, index) in taskUsers"
      >
        <task-users-name
          :key="index"
          class="mr-5 flex-shrink-0"
          :user-info="item"
          :friends-list="friendsList"
        ></task-users-name>
      </template>
    </div>
  </div>
</template>

<script>
import tools from '@/api/tools'
import TaskUsersName from './TaskUsersName'
export default {
  name: 'TaskUsers',
  components: {
    TaskUsersName
  },
  props: {
    task: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      taskUsers: [],
      friendsList: undefined
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      const printData = tools.getCookie('printData')
      const taskUsers = printData.taskUsers
      const friendsList = printData.friendsList
      this.$set(this, 'taskUsers', taskUsers)
      this.$set(this, 'friendsList', friendsList)
    }
  }
}
</script>

<style scoped lang="scss">
.v_print_page_task_view_main_task_users{
  width: 100%;
  background-color: #fff;
  min-height: 50px;
  border-bottom: 1px solid #f4f4f4;
  .icon{
    @include background(24px, 24px);
    width:24px;
    height: 50px;
    background-image: url(~@/assets/images/task/task_user_icon.png);
    margin-right: 16px;
    align-self: flex-start;
  }
  .list{
    flex: 1;
    color: #666;
    font-size: 14px;
    .user_name{
      margin-right: 5px;
    }
  }
}
</style>
