<template>
  <div
    class="v_print_page_task_view_main_task_users_name"
  >
    {{ userName }}
  </div>
</template>

<script>
export default {
  name: 'TaskUsersName',
  props: {
    userInfo: {
      type: Object,
      default: undefined
    },
    friendsList: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    userName() {
      // 优先备注名 》 群聊名 》 默认名
      const { userInfo, friendsList } = this
      if (!userInfo) {
        return ''
      }
      const { chatUserName, userId, userName } = userInfo
      const find = friendsList.find((item) => {
        return Number(item.friendUserId) === Number(userId)
      })
      if (find) {
        const { friendRemark } = find
        if ((friendRemark ?? '') !== '') {
          return friendRemark
        }
      }
      if ((chatUserName ?? '') !== '') {
        return chatUserName
      }
      return userName
    }
  }
}
</script>
