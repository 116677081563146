<template>
  <div
    class="v_print_page_task_view_main_widget_wrapper bbox d-flex flex-column bbox"
  >
    <template
      v-if="!list || (list && list.length == 0) "
    >
      <slot></slot>
    </template>
    <!-- un_drag不可拖拽 不可拖拽导致无法点击获取焦点 -->
    <!-- :group="{name:`deep_level`, pull: 是否可以拖出,}" -->
    <draggable
      class="draggable_wrapper"
      :class="{ position: !relevancy }"
      :list="list"
      :group="{ name:`widget`, pull: false }"
      handle=".components_task_main_widget_title_page .icon"
      animation="300"
      @change="changeLog"
      :style="{ minHeight: `${minHeight}px` }"
      :id="`control_section${ relevancy ? 'relevancy' : '' }`"
    >
      <template
        v-for="(item) in list"
      >
        <widget-wrapper
          class="widget_wrapper"
          :key="item.id?`widget_id_${item.id}`:`widget_sort_${item.widget_sort}` "
          :item="item"
          :relevancy="relevancy"
          :editable="editable"
          :parent="parent"
          :public_notice="public_notice"
          :recycle="recycle"
          :sell="sell"
          :system="system"
          :shopping_mail="shopping_mail"
          @handleRepeal="repealWidget"
          @handleDelete="deleteWidget"
          @handleDeleteRelevancy="deleteRelevancy"
          @handleMofifiedSign="modifiedSign"
          @handleSortFile="handleSortFile"
          ref="widget"
          @setZIndex="setZIndex"
          :heightlight="$api.moduleTask.widgetHeightlight(item)"
        ></widget-wrapper>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'WidgetContainer',
  components:{
    draggable,
    WidgetWrapper: () => import('@/components/Task/Main/Widget/Wrapper.vue')
  },
  data() {
    return {
      // scrollto 配置
      options: {
        container: '#control_section', //容器
        easing: 'ease-in',
        offset: 0,
        force: true,
        cancelable: true,
        x: false,
        y: true
      },
      init_status: false, // init函数是否正式执行
      scrollToWidget_status: false,
      timeout: null
    }
  },
  props: {
    task: {
      type: Object,
      default: undefined
    },
    // list: {
    //   type: Array,
    //   default: function(){
    //     return []
    //   }
    // },
    relevancy: {
      type: Boolean,
      default: true
    },
    minHeight: {
      type: Number,
      default: 20
    },
    parent: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    public_notice: {
      type: Boolean,
      default: false
    },
    recycle: {
      type: Boolean,
      default: false
    },
    system: {
      type: Boolean,
      default: false
    },
    sell: {
      type: Boolean,
      default: false
    },
    shopping_mail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    list() {
      const { task } = this
      return task.taskDeatails
    }
  },
  methods: {
    /**
     * @description: 控件列表数据变化钩子
     * @param {*} evt
     * @return {*}
     */
    changeLog: function() {
      const {parent} = this
      if (parent) {
        this.$set(parent, 'moved', true)
        this.$set(parent, 'modified', true)
      } else {
        this.$store.commit(
          'set_task_config',
          Object.assign(
            {},
            this.$store.state.task_config,
            { moved: true }
          )
        )
      }
    },
    addNewWidget() {
      this.scrollToBottom()
    },
    /**
     * @description: 滚动到底部
     */
    scrollToBottom() {
      // 获取最后一个元素
      if (this.$refs.widget && this.$refs.widget.length != 0) {
        let element = this.$refs.widget[this.$refs.widget.length - 1]
        if (element) {
          this.$scrollTo(element.$el,200, this.options)
        }
      }
    },
    async repealWidget(data) {
      const { item, parent } = data
      const { task_original } = this.$store.state
      const { taskDeatails } = task_original
      const { list } = this
      if (parent == null) {
        const obj = taskDeatails.find((unit) => {
          return unit.id == item.id && unit.id
        })
        const index = list.findIndex((unit) => {
          return unit.id === obj.id
        })
        // TODO 如果是智慧屏 则只撤销标题
        if (Number(item.type) === 11) {
          this.$set(list, index, Object.assign(
            {},
            list[index],
            {remark: obj.remark}, // 只撤销标题
            {repeal_title: true, repeal_content: true, islock: 0, userlock: '', }
          ))
        } else {
          this.$set(list, index, Object.assign(
            {},
            this.$tools.deepClone(obj),
            {repeal_title: true, repeal_content: true, islock: 0, userlock: '', }
          ))
        }
      } else {
        const parent_obj = taskDeatails.find((unit) => {
          return unit.id == parent.id && unit.id
        })
        const obj = parent_obj.controlLinkList.find((unit) => {
          return unit.id == item.id && unit.id
        })
        const index = parent.controlLinkList.findIndex((unit) => {
          return unit.id == obj.id
        })
        this.$set(parent.controlLinkList, index, Object.assign(
          {},
          this.$tools.deepClone(obj),
          {repeal_title: true, repeal_content: true, islock: 0, userlock: '', }
        ))
      }
      await this.$api.moduleTask.removeLock(item)
    },
    deleteWidget(data) {
      this.$emit('handleDeleteWidget', data)
    },
    deleteRelevancy(data) {
      this.$emit('handleDeleteRelevancy', data)
    },
    switchSidebar() {
      this.$refs.wrapper.switchSidebar()
    },
    modifiedSign(params) {
      this.$emit('handleMofifiedSign', params)
    },
    init() {
      this.$showLoading({text:'加载中'});
      const {list, relevancy, init_status} = this;
      if(init_status){
        this.$hideLoading();
        return;
      }
      if(list && list.length && !this.$refs.widget){
        // // console.log('this.$refs.widget 不存在 则200ms检测执行一次')
        // this.$refs.widget 不存在 则200ms检测执行一次
        setTimeout(()=>{
          this.init();
        },50);
        return;
      }
      // // console.log(relevancy, 'revelancy')
      if(relevancy){
        this.init_status = true;
        this.$hideLoading();
        return;
      }
      const {task_config} = this.$store.state;
      const {appoint_widget} = task_config;
      if(appoint_widget){
        const {parent_id, widget_id} = appoint_widget;
        const index = list.findIndex(item=>{
          if(parent_id){
            return item.id == parent_id;
          }
          return item.id == widget_id;
        })
        if(index == -1){
          // 控件已删除 直接打开模板 不再滚动
          this.init_status = true;
          this.$hideLoading();
          return;
        }
        if(parent_id){
          this.$set(list[index], 'switch_relevancy', true); //将关联打开
          this.$nextTick(function(){
            this.$refs.widget[index].scrollToWidget(widget_id);
          })
        }else{
          // 跳转到一级控件
          if(this.$refs.widget && this.$refs.widget[index] && this.$refs.widget[index].$el){

            this.$scrollTo( this.$refs.widget[index].$el,20, this.options);
            clearTimeout(this.timeout);
            this.timeout =  setTimeout(()=>{
              const offsetParent = this.$refs.widget[index].$el.offsetParent;
              const parent_scrollTop = offsetParent.scrollTop; // 距离顶部高度
              const parent_scrollHeight = offsetParent.scrollHeight; // 滚动总高度
              const parent_offsetHeight = offsetParent.offsetHeight; // 自身高度
              const offsetTop = this.$refs.widget[index].$el.offsetTop;
              // 目标控件offsetTop和父控件scrollTop 相差小于50 || 已经滚动到底部(距离底部100)
              if((Math.abs(parent_scrollTop - offsetTop) < 50) || (Math.abs(parent_scrollHeight - (parent_offsetHeight + parent_scrollTop)) < 100)){
                this.init_status = true; // 执行完毕标记
                this.$hideLoading();
              }else{
                this.init();
              }
            }, 50)
          }else{
            this.init();
          }
        }
        return;
      }
      this.$hideLoading();
    },
    scrollToWidget(widget_id) {
      // console.log(widget_id)
      this.$showLoading({text:'加载中'});
      const {list, scrollToWidget_status} = this;
      if(scrollToWidget_status){
        this.$hideLoading();
        return;
      }
      const index = list.findIndex(item=>{
        return item.id == widget_id;
      })

      if(index != -1){
        if(this.$refs.widget[index] && this.$refs.widget[index].$el){

          this.$scrollTo( this.$refs.widget[index].$el,20, this.options);
          clearTimeout(this.timeout);
          this.timeout =  setTimeout(()=>{
            const offsetParent = this.$refs.widget[index].$el.offsetParent;
            const parent_scrollTop = offsetParent.scrollTop; // 距离顶部高度
            const parent_scrollHeight = offsetParent.scrollHeight; // 滚动总高度
            const parent_offsetHeight = offsetParent.offsetHeight; // 自身高度
            const offsetTop = this.$refs.widget[index].$el.offsetTop;
            // 目标控件offsetTop和父控件scrollTop 相差小于50 || 已经滚动到底部
            if((Math.abs(parent_scrollTop - offsetTop) < 100) || (Math.abs(parent_scrollHeight - (parent_offsetHeight + parent_scrollTop))< 50)){
              this.scrollToWidget_status = true; // 执行完毕标记
              this.$hideLoading();
            }else{
              this.scrollToWidget(widget_id);
            }
          }, 50)
        }else{
          this.scrollToWidget(widget_id);
        }
      }
    },
    setZIndex(zindex) {
      this.$emit('setZIndex', zindex);
    },
    handleSortFile(params) {
      this.$emit('handleSortFile', params);
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.init()
    })
  }
}
</script>

<style lang="scss" scoped>
.v_print_page_task_view_main_widget_wrapper{
  width: 100%;
  height: 100%;
  //overflow-y: auto;
  .draggable_wrapper{
    @include scrollbar;
    //overflow-y: auto;
    width: 100%;
    max-height: 100%;
    flex: 1;
    &.position{
      position: relative;
    }
  }
  .widget_wrapper{
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
  }

}
</style>
