var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v_print_page_task_view_main_index bbox d-flex flex-column",
    },
    [
      _c("task-users", { staticClass: "px-20", attrs: { task: _vm.task } }),
      _c("task-title", { staticClass: "px-20", attrs: { task: _vm.task } }),
      _c("task-tag", { staticClass: "px-20", attrs: { task: _vm.task } }),
      _c("widget-container", {
        staticClass: "pa-20",
        attrs: { task: _vm.task },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }