<template>
  <div
    class="v_print_page_task_view_main_index bbox d-flex flex-column"
  >
    <task-users
      class="px-20"
      :task="task"
    ></task-users>
    <task-title
      class="px-20"
      :task="task"
    ></task-title>
    <task-tag
      class="px-20"
      :task="task"
    ></task-tag>
    <widget-container
      class="pa-20"
      :task="task"
    ></widget-container>
  </div>
</template>

<script>
import TaskUsers from '@/views/PrintPage/TaskView/Main/TaskUsers'
import TaskTitle from '@/views/PrintPage/TaskView/Main/TaskTitle'
import TaskTag from '@/views/PrintPage/TaskView/Main/TaskTag'
import WidgetContainer from '@/views/PrintPage/TaskView/Main/Widget/Container'
export default {
  name: 'index',
  components: {
    TaskUsers,
    TaskTitle,
    TaskTag,
    WidgetContainer
  },
  props: {
    task: {
      type: Object,
      default: undefined
    }
  },
  provide: {
    printMode: true
  }
}
</script>

<style scoped lang="scss">
.v_print_page_task_view_main_index {
  width: 100%;
}
</style>
